import { IconMenu2 } from "@tabler/icons-react";
import { Logo } from "~/components/logo";
import { Sheet, SheetContent, SheetTrigger } from "~/components/ui/sheet";
import { cn } from "~/lib/utils";
import { RenderSideNavigationItems } from "./side-navigation";
import { NavigationProps } from "./types";
import { UserMenu } from "./usermenu";

function MobileButton() {
  return (
    <div className="flex items-center">
      <IconMenu2 size="30px" className="text-primary-foreground" />
    </div>
  );
}

interface MobileNavigationProps extends NavigationProps {
  trigger?: React.ReactNode;
  cnTrigger?: string;
}

export function MobileNavigation({
  trigger,
  cnTrigger,
  navItems,
}: MobileNavigationProps) {
  const Trigger = trigger || <MobileButton />;
  return (
    <Sheet>
      <SheetTrigger className={cn(cnTrigger)}>{Trigger}</SheetTrigger>
      <SheetContent className="bg-white p-0">
        <div className="w-full h-full flex flex-col justify-between">
          <div>
            <div className="pb-4 pt-2 pl-2">
              <Logo className="h-[50px]" />
            </div>
            <div className="mt-2 ">
              <RenderSideNavigationItems navItems={navItems} />
            </div>
          </div>
          <div className="border-t">
            <UserMenu compact={false} />
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}
