"use client";

import { Avatar, AvatarFallback } from "~/components/ui/avatar";
import { LinkButton } from "~/components/ui/button";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import {
  useAccessToken,
  useGetCurrentUser,
  useHasRole,
  useLogout,
} from "~/features/auth/api/auth-hooks";
import { userDisplayName } from "~/lib/formatter";
import { cn } from "~/lib/utils";

import { Link, useNavigate } from "@remix-run/react";
import {
  IconChevronDown,
  IconLogout,
  IconSettings,
  IconSpy,
} from "@tabler/icons-react";
import { useEffect } from "react";
import { AppLink } from "./applink";

const ENABLE_SIGNUP = false;

function UserMenuLoggedOut({ compact }: { compact?: boolean }) {
  return (
    <>
      <div
        className={cn(
          compact ? "flex items-center space-x-1" : "flex flex-col p-2 gap-2"
        )}
      >
        <LinkButton
          variant="outline"
          href="/auth/signin"
          size={compact ? "default" : "lg"}
          fullwidth={true}
        >
          Pristupite časovima
        </LinkButton>
        {ENABLE_SIGNUP && (
          <LinkButton
            variant="default"
            href="/auth/signup"
            size={compact ? "default" : "lg"}
            fullwidth={true}
          >
            Sign up
          </LinkButton>
        )}
      </div>
    </>
  );
}

function UserAvatar() {
  const { data: currentUser } = useGetCurrentUser();
  if (!currentUser) {
    return null;
  }
  return (
    <div className="flex items-center">
      <Avatar>
        <AvatarFallback>
          {currentUser.first_name[0]}
          {currentUser.last_name[0]}
        </AvatarFallback>
      </Avatar>
      <IconChevronDown className="size-5" stroke={"2px"} />
    </div>
  );
}

function UserText() {
  const { data: currentUser } = useGetCurrentUser();
  if (!currentUser) {
    return null;
  }
  return (
    <div className="flex items-center w-full hover:bg-accent p-2">
      <div>
        <IconSettings className="size-7 mr-1 text-primary-foreground" />
      </div>
      <div className="flex flex-col items-start overflow-ellipsis overflow-hidden whitespace-nowrap">
        <span className="text-md font-bold overflow-ellipsis w-full overflow-hidden text-left">
          {userDisplayName(currentUser)}
        </span>
        <span className="text-sm font-light overflow-ellipsis w-full overflow-hidden text-left">
          {currentUser.email}
        </span>
      </div>
    </div>
  );
}

function UserMenuLoggedIn({ compact }: { compact?: boolean }) {
  const logout = useLogout();
  const { data: currentUser, error: currentUserError } = useGetCurrentUser();
  const navigate = useNavigate();
  const isAdmin = useHasRole("role_admin");

  useEffect(() => {
    if (currentUserError) {
      logout().then(() => {
        navigate("/auth/signin");
      });
    }
  }, [currentUserError]);
  if (!currentUser) {
    return null;
  }
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="focus:outline-none w-full">
          {compact ? <UserAvatar /> : <UserText />}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {isAdmin && (
            <Link to="/administration">
              <DropdownMenuItem className="cursor-pointer">
                <IconSpy className="size-4 mr-2" />
                Administration
              </DropdownMenuItem>
            </Link>
          )}
          <DropdownMenuItem onClick={logout} className="cursor-pointer">
            <IconLogout className="size-4 mr-2" />
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}

export function UserMenu({
  compact,
  enterAppButton,
}: {
  compact?: boolean;
  enterAppButton?: boolean;
}) {
  const accessToken = useAccessToken();
  if (accessToken && !enterAppButton) {
    return <UserMenuLoggedIn compact={compact} />;
  } else if (accessToken && enterAppButton) {
    return <AppLink />;
  } else {
    return <UserMenuLoggedOut compact={compact} />;
  }
}
