import { format, isToday, isTomorrow } from "date-fns";
interface UserWithNames {
  first_name: string;
  last_name: string;
}
export function userDisplayName(user: UserWithNames) {
  return user.first_name + ", " + user.last_name;
}

export function formatBytes(bytes: number, decimals: number = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function formatDate(iso_date: string) {
  return format(new Date(iso_date), "dd MMM yyyy");
}

export function formatDateLong(iso_date: string) {
  if (isToday(iso_date))
    return "Danas, " + format(new Date(iso_date), "do MMMM yyyy");
  if (isTomorrow(iso_date))
    return "Sutra, " + format(new Date(iso_date), "do MMMM yyyy");
  return format(new Date(iso_date), "EEEE, do MMMM yyyy");
}

export function formatTime(iso_time: string) {
  return format(new Date(iso_time), "HH:mm");
}

export function formatDateTime(iso_datetime: string) {
  return format(new Date(iso_datetime), "dd MMM yyyy, HH:mm");
}

export function maxLengthString(str: string, maxLength: number) {
  return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
}
