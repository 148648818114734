import { Link, useLocation } from "@remix-run/react";
import { IconChevronsLeft } from "@tabler/icons-react";
import { Logo } from "~/components/logo";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "~/components/ui/collapsible";
import { iconMap } from "~/components/ui/icons";
import { cn, isPathActive } from "~/lib/utils";
import { NavigationItem, NavigationProps } from "./types";
import { UserMenu } from "./usermenu";

function Icon({
  icon,
  className,
}: {
  icon?: string | null;
  className?: string;
}) {
  const IconComponent = icon ? iconMap.get(icon) : undefined;
  return IconComponent ? <IconComponent className={className} /> : null;
}

function SideNavigationChildItem({ navItem }: { navItem: NavigationItem }) {
  const location = useLocation();
  const isActive = isPathActive(
    navItem.url,
    location.pathname,
    navItem.basePath || "/"
  );
  return (
    <div>
      <div
        className={cn(
          "p-1 px-3 ml-3 rounded-sm hover:bg-accent",
          isActive && "bg-accent"
        )}
      >
        <Link to={navItem.url || ""} className="flex items-center space-x-1">
          <Icon
            icon={navItem.icon}
            className={`border-primary-foreground  mr-2 rounded-sm p-0.5 size-6
          ${
            isActive
              ? "bg-primary-foreground text-white"
              : "text-primary-foreground"
          }`}
          />

          <span className={"text-md font-medium"}>{navItem.label}</span>
        </Link>
      </div>
    </div>
  );
}

function SideNavigationItemWithChildren({
  navItem,
}: {
  navItem: NavigationItem;
}) {
  const location = useLocation();
  const isActive = isPathActive(
    navItem.url,
    location.pathname,
    navItem.basePath || "/"
  );
  const isChildActive = navItem.children?.some((child) =>
    isPathActive(child.url, location.pathname, child.basePath || "/")
  );

  return (
    <Collapsible open={isChildActive || undefined}>
      <div
        className={cn(
          "p-2 px-3 rounded-sm hover:bg-accent",
          isActive && "bg-accent"
        )}
      >
        {navItem.children ? (
          <CollapsibleTrigger
            className={cn(
              "flex items-center space-x-1 justify-between cursor-pointer group w-full",
              isChildActive && "cursor-default"
            )}
          >
            <div className="flex items-center space-x-1">
              <Icon
                icon={navItem.icon}
                className={`border-primary-foreground  mr-2 rounded-sm p-0.5 size-7 text-primary-foreground`}
              />
              <span className={"text-lg font-medium"}>{navItem.label}</span>
            </div>
            <IconChevronsLeft
              className={cn(
                "text-primary-foreground group-data-[state=open]:-rotate-90 transition-transform"
              )}
            />
          </CollapsibleTrigger>
        ) : (
          <Link to={navItem.url || ""} className="flex items-center space-x-1">
            <Icon
              icon={navItem.icon}
              className={`border-primary-foreground  mr-2 rounded-sm p-0.5 size-7
                ${
                  isActive
                    ? "bg-primary-foreground text-white"
                    : "text-primary-foreground"
                }`}
            />

            <span className={"text-lg font-medium"}>{navItem.label}</span>
          </Link>
        )}
      </div>
      <CollapsibleContent>
        {navItem.children?.map((child, index) => (
          <SideNavigationChildItem key={index} navItem={child} />
        ))}
      </CollapsibleContent>
    </Collapsible>
  );
}

export function SideNavigationItem({ navItem }: { navItem: NavigationItem }) {
  const location = useLocation();
  const isActive = isPathActive(
    navItem.url,
    location.pathname,
    navItem.basePath || "/"
  );
  return (
    <div className="p-2 px-3 rounded-sm hover:bg-accent">
      <Link to={navItem.url || ""} className="flex items-center space-x-1">
        <Icon
          icon={navItem.icon}
          className={`border-primary-foreground  mr-2 rounded-sm p-0.5 size-7
          ${
            isActive
              ? "bg-primary-foreground text-white"
              : "text-primary-foreground"
          }`}
        />

        <span className={"text-lg font-medium"}>{navItem.label}</span>
      </Link>
    </div>
  );
}

export function RenderSideNavigationItems(props: NavigationProps) {
  const { navItems } = props;
  return (
    <>
      {navItems.map((navItem, index) =>
        navItem.children?.length ? (
          <SideNavigationItemWithChildren key={index} navItem={navItem} />
        ) : (
          <SideNavigationItem key={index} navItem={navItem} />
        )
      )}
    </>
  );
}

interface SideNavigationItemProps extends NavigationProps {
  mobile?: boolean;
}
export function SideNavigation({ navItems }: SideNavigationItemProps) {
  return (
    <div className="w-full h-full flex flex-col justify-between">
      <div>
        <div className="pb-4 pt-2 pl-2">
          <Logo className="h-[50px]" />
        </div>
        <div className="mt-2 ">
          <RenderSideNavigationItems navItems={navItems} />
        </div>
      </div>
      <div className="border-t w-full">
        <UserMenu compact={false} />
      </div>
    </div>
  );
}
