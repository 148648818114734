"use client";

import { Link } from "@remix-run/react";
import { IconArrowBigRight } from "@tabler/icons-react";
import { Button } from "~/components/ui/button";
import { useAccessToken } from "~/features/auth/api/auth-hooks";

export function AppLink({ compact }: { compact?: boolean }) {
  const accessToken = useAccessToken();
  if (!accessToken) {
    return (
      <Link to="/auth/signin">
        <Button className="px-2">Pristupite časovima</Button>
      </Link>
    );
  }
  return (
    <Link to="/app">
      <Button>
        <IconArrowBigRight size={20} className="mr-1" />
        Učitaj aplikaciju
      </Button>
    </Link>
  );
}
